// main - https://utake.ukrpay.net/webadmin/
// test - http://192.168.65.156:9710
// test-mobile - http://192.168.65.71:4431

import { authUrls } from "../dataUI/common/commonConsts";

export const joinposterId = "3176";
export const application_secret = "f7ec4402bbd7e149ee465458e88a505f";

/* dev/prod */
//export const backendUrl = "http://192.168.65.156:9714";
//export const backendUrl = "http://192.168.65.71:4431";
export const backendUrl = "https://adminsamo.com.ua/webadmin/";

//https://192.168.65.156:80

//export const joinPosterConfirmUrl = `http://localhost:3000/${authUrls.REG_CONFIRM}`;
export const joinPosterConfirmUrl = `https://adminsamo.com.ua/${authUrls.REG_CONFIRM}`;

import $api from "../http";
import {
  application_secret,
  joinPosterConfirmUrl,
  joinposterId,
} from "../../config/config";

export default class JoinPosterService {
  static async authByJoinPosterToken(account, code) {
    const data = {
      redirect_uri: joinPosterConfirmUrl,
      code: code,
    };
    return $api.post(
      `https://poster.ukrpay.net/application/${account}/register-merchant`,
      data,
    );
  }

  static async synchronizeMerchant(token, account) {
    return $api.post(
      `https://poster.ukrpay.net/application/init/syncronize?account=${account}&token=${token}`,
    );
  }

  static async getMerchantData(code) {
    return $api.post(`https://poster.ukrpay.net/application/auth/cpanel`, {
      code,
    });
  }

  static async updateStore(data) {
    return $api.put(`https://poster.ukrpay.net/spots/`, { ...data });
  }

  static async updateStorePhoto(f, spot_id, newName) {
    const newF = new File([f], newName, { type: f.type });
    const file = new FormData();
    file.append("file", newF);
    return $api.post(
      `https://poster.ukrpay.net/application/posterspotimage/${spot_id}`,
      file,
    );
  }

  static async updateMerchant(data) {
    return $api.put(
      `https://poster.ukrpay.net/application/posterupdatemerchant/`,
      { ...data },
    );
  }

  static async updateMerchantLogo(f, merchantId, newName) {
    const newF = new File([f], newName, { type: f.type });
    const file = new FormData();
    file.append("file", newF);

    return $api.post(
      `https://poster.ukrpay.net/application/postermerchantlogo/${merchantId}`,
      file,
    );
  }
}
